import CardTramite from "../components/User/CardTramite";
import CardUser from "../components/User/CardUser";

export default function UserContainer({
  data,
  visible,
  setVisible,
  setInicio,
  inicio,
}) {
  const tramites = [
    {
      logo: "tramites/tad.svg",
      titulo: "Trámites a Distancia",
      descripcion:
        "Hacé tus trámitaciones ante la Administración Pública Nacional.",
      url: "https://tramitesadistancia.gob.ar/#/inicio",
    },
    {
      logo: "tramites/universidades.svg",
      titulo: "Universidades",
      descripcion: "Conocé o inscribite en las diferentes casas de estudio.",
      url: "#",
    },
    {
      logo: "tramites/migratorios.svg",
      titulo: "Trámites Migratorios",
      descripcion: "Realizá consultas ante el Ministerio del Interior.",
      url: "#",
    },
    {
      logo: "tramites/mundo.svg",
      titulo: "Acceso a la Información Pública",
      descripcion:
        "Consultá la información generada o administrada por las autoridades públicas.",
      url: "#",
    },
    {
      logo: "tramites/candado.svg",
      titulo: "Datos Abiertos",
      descripcion:
        "Accedé a datos públicos en formatos abiertos para que puedas compartirlos.",
      url: "#",
    },
    {
      logo: "tramites/otros.svg",
      titulo: "Otros Trámites",
      descripcion: "Buscá otros trámites disponibles",
      url: "#",
    },
  ];

  return (
    <div className="h-full w-full flex flex-col flex-grow justify-start items-center bg-primary gap-10 py-8 px-4">
      <div class="container mx-auto flex flex-col gap-8 justify-between items-center">
        <section
          onClick={() => setInicio(!inicio)}
          className="w-full h-max flex flex-col justify-start items-start gap-4 cursor-pointer"
        >
          <h2 className="text-4xl font-bold text-primaryText">
            Portal de Gestiones Argentina
          </h2>
          <h4 className="text-base font-medium text-primaryText">
            Proyecto Ciudadano Rioplatense
          </h4>
        </section>
        <section className="2xl:w-8/12 w-10/12 flex flex-col justify-start items-center bg-white rounded-xl">
          <CardUser data={data} visible={visible} setVisible={setVisible} />
        </section>
        <section className="w-full grid grid-cols-3 gap-8">
          {tramites.map((tramite, index) => (
            <CardTramite key={index} {...tramite} />
          ))}
        </section>
      </div>
    </div>
  );
}
