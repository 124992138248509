import Keycloak from "./keycloak";

const body = {
  realm: "test-broker",
  url: window.location.search.includes("prd")
    ? "https://autenticar.gob.ar/auth"
    : "https://tst.autenticar.gob.ar/auth",
  "ssl-required": "external",
  clientId: "broker-app",
  credentials: {
    secret: window.location.search.includes("prd")
      ? "f7cd0388-c818-475b-82ec-3a3bbcd1cb55"
      : "b02bd5e3-cbec-4ed8-926d-4089bd47a180",
  },
};
export const keycloak = Keycloak(body);
