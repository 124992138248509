import { useState, useEffect, useRef } from "react";

export default function Desplegable({
  data,
  logout,
  visible,
  setVisible,
  inicio,
  setInicio,
}) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  const handleVisible = () => {
    if (inicio) {
      setInicio(!inicio);
    } else if (!visible) {
      setVisible(!visible);
    }
  };

  const handleToggle = (event) => {
    event.stopPropagation(); // Stop the click event from propagating
    setOpen(!open);
  };

  return (
    <div className="relative flex justify-center">
      <button
        onClick={handleToggle}
        className="bg-transparent relative flex justify-between items-center gap-2 rounded-full text-white font-bold text-base hover:bg-white/25 px-4 py-2"
      >
        <div className="flex items-center justify-start">
          <h2 className="text-base font-bold first-letter:uppercase text-left text-white">
            {`${data.given_name} ${data.family_name}` || "Juan"}
          </h2>
        </div>
        <img
          src={"flecha.svg"}
          width={15}
          height={15}
          alt="Flecha desplegable"
        />
      </button>
      {open && (
        <div
          ref={ref}
          className="absolute top-10 right-0 w-48 bg-white border-2 border-slate-300 rounded-xl shadow-xl"
        >
          <div
            onClick={handleVisible}
            className="flex flex-col w-full justify-between items-start gap-2 p-3 rounded-t-xl hover:bg-slate-100"
          >
            <h2 className="text-base font-medium text-left w-full text-primaryText cursor-pointer">
              Mi perfil
            </h2>
          </div>
          <div
            onClick={() => logout()}
            className="flex flex-col w-full justify-between items-start gap-2 p-3 hover:bg-slate-100 hover:rounded-b-xl border-slate-300"
          >
            <h2 className="text-base font-medium text-left w-full text-primaryText cursor-pointer">
              Cerrar la sesión
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}
