export default function LoginContainer({ auth }) {
  return (
    <div className="h-full w-full flex flex-grow flex-col items-center px-32 justify-evenly bg-primary">
      <div class="container mx-auto flex flex-row-reverse justify-between items-center">
        <img src={"logored.webp"} width={332} height={326} alt="logo red" />
        <div className="flex flex-col gap-8 justify-center w-2/5 2xl:w-2/5 items-start">
          <div className="flex flex-col gap-2 items-start">
            <h2 className="text-5xl font-bold text-primaryText text-left ">
              Portal de Gestiones Argentina
            </h2>
            <h4 className="text-[28px] font-medium text-primaryText text-left ">
              Proyecto Ciudadano Rioplatense
            </h4>
          </div>
          {auth ? (
            <button
              className="text-white bg-terciary px-8 py-4 w-5/12 font-bold text-xl rounded-full hover:bg-primary hover:text-terciary ring-inset hover:ring-2 hover:ring-terciary transition duration-500 ease-in-out"
              onClick={auth}
            >
              INGRESAR
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
