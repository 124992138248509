import { useEffect, useState } from "react";
import { keycloak } from "../services/instance";
import LoginContainer from "./LoginContainer";
import UserContainer from "./UserContainer";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import Loading from "./Loading";

export default function HomeContainer() {
  const [data, setData] = useState(null);
  const [sesion, setSesion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);
  const [inicio, setInicio] = useState(false);

  useEffect(() => {
    keycloak
      .init()
      .success(async () => {
        if (keycloak.authenticated) {
          setSesion(keycloak);
          setLoading(true);
          await fetch(
            window.location.search.includes("prd")
              ? "https://autenticar.gob.ar/auth/realms/test-broker/protocol/openid-connect/userinfo"
              : "https://tst.autenticar.gob.ar/auth/realms/test-broker/protocol/openid-connect/userinfo",
            {
              headers: {
                Authorization: "Bearer " + keycloak.token,
              },
            }
          ).then((response) => {
            response.json().then((data) => {
              setData({
                ...data,
                token: keycloak.token,
              });
              setLoading(false);
            });
          });
        }
      })
      .error((error) => {
        console.log(error);
      });
  }, []);

  const auth = () => {
    keycloak.init({ onLoad: "login-required" });
  };

  const logout = () => {
    sesion.logout();
  };

  return (
    <>
      <Header
        data={data}
        logout={logout}
        visible={visible}
        setVisible={setVisible}
        inicio={inicio}
        setInicio={setInicio}
      />
      {inicio ? (
        <LoginContainer auth={auth} />
      ) : loading ? (
        <Loading />
      ) : data !== null ? (
        <UserContainer
          data={data}
          visible={visible}
          setVisible={setVisible}
          setInicio={setInicio}
          inicio={inicio}
        />
      ) : (
        <LoginContainer auth={auth} />
      )}

      <Footer />
    </>
  );
}
