export default function DataCard({ visible, data }) {
  const tipoId = (uid) => {
    if (uid.includes("ar")) {
      return "DNI";
    } else if (uid.includes("uy")) {
      return "Cédula de Identidad";
    }
  };

  const transformUid = (uid) => {
    const uidArray = uid.split("-");
    return uidArray[uidArray.length - 1];
  };

  const info = {
    "Nombre y Apellido":
      data.given_name + " " + data.family_name || "Juan Jose Perez",
    Email: data.email || "No informado",

    "Autenticación provista por": data.uid
      ? "República Oriental del Uruguay"
      : "República Argentina",

    "Última autenticación": "25/10/2023, 15:32hs",
    Identificador: data.cuit || transformUid(data.uid) || "1234567890",
    "Tipo de identificador": data.cuit ? "CUIT" : `${tipoId(data.uid)}`,
  };

  const nivel = {
    0: "1 - Bajo",
    1: "1 - Bajo",
    2: "2 - Medio",
    3: "3 - Alto",
    4: "4 - Muy Alto",
  };

  const transformarNivel = (nivel) => {
    if (data.rid) {
      const ridArray = data.rid.split(":");
      return nivel[ridArray[ridArray.length - 1]];
    } else {
      return nivel[data.nivel];
    }
  };

  const estilos = visible
    ? "w-full overflow-hidden max-h-[300px] transition-[max-height] border-[3px] border-fondoCard duration-700 ease-in-out px-4  pt-4 gap-4 flex flex-col justify-between bg-white rounded-b-xl shadow-xl "
    : "w-full overflow-hidden max-h-0 transition-[max-height] duration-500 ease-out flex justify-between bg-white rounded-b-xl shadow-xl border-b-2 border-x-2 border-slate-200";

  return (
    <div className={`${estilos}`}>
      <div className="w-full gap-4 flex items-start justify-between p-4">
        {/* {Object.keys(info).map((key, index) => {
          return (
            <div
              key={index}
              className="w-full flex flex-col justify-start items-start gap-1"
            >
              <p className="text-[21px] font-bold text-slate-900">{key}</p>
              <h2 className="text-[21px] font-normal text-primaryText">
                {info[key]}
              </h2>
            </div>
          );
        })} */}
        <div className="flex flex-col items-start justify-start gap-8">
          <div className="flex flex-col items-start">
            <p className="text-[21px] font-bold text-slate-900">
              {"Nombre y Apellido"}
            </p>
            <div className="flex items-center justify-start gap-2">
              <h2 className="text-[21px] font-normal first-letter:uppercase text-primaryText">
                {info["Nombre y Apellido"]}
              </h2>
            </div>
          </div>

          <div className="flex flex-col items-start">
            <p className="text-[21px] font-bold text-slate-900">{"Email"}</p>
            <h2 className="text-[21px] font-normal text-primaryText">
              {info["Email"]}
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-1">
          <p className="text-[21px] font-bold text-slate-900">
            {"Identificador"}
          </p>
          <h2 className="text-[21px] font-normal text-primaryText">
            Número: {info["Identificador"]}
          </h2>
          <h2 className="text-[21px] font-normal text-primaryText">
            Tipo: {info["Tipo de identificador"]}
          </h2>
        </div>
        <div className="flex flex-col items-start justify-start gap-1">
          <p className="text-[21px] font-bold text-slate-900">
            {"Autenticación"}
          </p>
          <h2 className="text-[21px] font-normal text-primaryText">
            {info["Autenticación provista por"]}
          </h2>
          <h2 className="text-[21px] font-normal text-primaryText">
            {info["Última autenticación"]}
          </h2>
          {data.nivel || data.rid ? (
            <div className="w-max flex justify-start items-center py-4">
              <p className="text-[21px] font-bold text-slate-900 whitespace-nowrap">
                Nivel de seguridad: {transformarNivel(nivel)}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
