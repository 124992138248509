export default function Footer() {
  return (
    <footer className="bg-white w-full  p-4 ">
      <div class="container mx-auto flex justify-between items-center">
        <img src={"argentinaunida.svg"} width={215} height={30} />
        <div className="flex items-end justify-center gap-12">
          <a
            href="https://www.argentina.gob.ar/jefatura/innovacion-publica/innovacion-administrativa/autenticar/terminos-y-condiciones"
            target="_blank"
            className="text-terciary text-base font-medium underline hover:text-indigo-700"
          >
            Términos y Condiciones
          </a>
          <a
            href="https://www.argentina.gob.ar/jefatura/innovacion-publica/innovacion-administrativa/autenticar"
            target="_blank"
            className="text-terciary text-base font-medium underline hover:text-indigo-700"
          >
            ¿Necesitás Ayuda?
          </a>
        </div>
      </div>
    </footer>
  );
}
