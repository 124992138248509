import "./App.css";
import HomeContainer from "./containers/HomeContainer";

function App() {
  return (
    <main className="flex min-h-screen h-max flex-grow flex-col items-center justify-between overflow-x-hidden">
      <HomeContainer />
    </main>
  );
}

export default App;
