import DataCard from "./DataCard";

export default function CardUser({ data, visible, setVisible }) {
  return (
    <div className="w-full  flex flex-col justify-start items-center bg-white rounded-full">
      <div
        onClick={() => setVisible(!visible)}
        className={`w-full cursor-pointer px-4 pb-6 pt-4 gap-2 flex flex-col items-start bg-fondoCard justify-between rounded-xl ${
          visible ? "rounded-b-none" : "rounded-b-xl"
        } ${visible ? "pb-1" : "pb-6"}`}
      >
        <div className="w-full px-2 flex items-center bg-fondoCard justify-between rounded-t-xl">
          <div className="flex items-center justify-start gap-2">
            <img
              src={"iconouser.svg"}
              width={30}
              height={30}
              alt="logousuario"
            />

            <p className="text-[28px] font-bold cursor-pointer text-white">
              Hola {data.given_name}
            </p>
          </div>
          {data.uid?.includes("uy") ? (
            <img
              src={"urubandera.svg"}
              width={80}
              height={80}
              alt="bandera uruguaya"
              // className="border-2 border-slate-200"
            />
          ) : (
            <img
              src={"argbandera.svg"}
              width={80}
              height={80}
              alt="bandera argentina"
              className="border-2 border-white"
            />
          )}
        </div>
        {visible ? null : (
          <button
            className="w-max px-10 py-3 flex items-center justify-center rounded-full bg-white group"
            onClick={() => setVisible(!visible)}
          >
            <p className="text-base font-bold text-terciary text-center">
              PERFIL COMPLETO
            </p>
          </button>
        )}
      </div>
      <DataCard visible={visible} data={data} />
    </div>
  );
}
