import Desplegable from "../components/Header/Desplegable";

export default function Header({
  data,
  logout,
  visible,
  setVisible,
  inicio,
  setInicio,
}) {
  return (
    <header className="bg-[#37bbed] w-screen py-4 px-4 lg:py-6">
      <div class="container mx-auto flex justify-between">
        <div className="flex items-start justify-start">
          <img
            src={"logo-autenticar.webp"}
            width={209}
            height={56}
            alt="Logo Autenticar"
            className="h-10 lg:h-14"
          />
          {/* <h1 className="text-4xl font-bold text-white">Autenticar</h1> */}
        </div>
        <div className="flex gap-8">
          <img
            src={"secretaria.svg"}
            width={125}
            height={56}
            alt="Secretaria de Innovacion Publica"
          />
          <img
            src={"jefatura.svg"}
            width={155}
            height={56}
            alt="Jefatura de Gabinete de Ministros"
          />
          {data !== null && (
            <Desplegable
              data={data}
              logout={logout}
              visible={visible}
              setVisible={setVisible}
              inicio={inicio}
              setInicio={setInicio}
            />
          )}
        </div>
      </div>
    </header>
  );
}
