export default function CardTramite({ logo, titulo, descripcion, url }) {
  return (
    <div className="w-full opacity-60 flex justify-start items-start bg-white rounded-xl border-2 border-slate-300 p-5 gap-4 cursor-pointer hover:bg-primary hover:shadow-xl transition-all duration-500 ease-in-out">
      <div className="flex flex-col justify-start items-start gap-2">
        <h2 className="text-xl font-bold text-primaryText">{titulo}</h2>
        <p className="text-base font-normal text-primaryText">{descripcion}</p>
      </div>
    </div>
  );
}
